<template>
  <section class="roomHeader has-background-primary" :style="backgroundImage">
    <div class="exit-button mr-2" @click="GoToMenu()">
      <d-icon icon="FaArrowLeft" />
    </div>
    <h5 class="title pb-2 is-size-5">{{ title ?? appTitle }}</h5>
  </section>
</template>

<script>
export default {
  data() {
    return {
      appTitle: process.env.VUE_APP_TITLE ?? "douob.online",
    };
  },
  props: {
    title: {
      type: String,
    },
  },
  methods: {
    GoToMenu() {
      this.$router.push("/");
    },
  },
  computed: {
    backgroundImage() {
      const setup = this.$store.state.setup;
      
      if (!setup.headerBackground) return null;
      return `background-image: url(${setup.headerBackground.url})`;
    },
  },
};
</script>
