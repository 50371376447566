<template>
  <div class="site">
    <main class="site-content is-flex is-flex-direction-column">
      <HeaderComponent :title="$t('event.map')" />
      <MapComponent />
    </main>
  </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent";
import MapComponent from "../components/MapComponent";

export default {
  components: {
    HeaderComponent,
    MapComponent,
  },
  mounted() {
    this.$socket.client.emit("player:JoinSpace", { spaceSlug: "map" }, () => {});
  },
  beforeMount() {
    if (!this.$store.state.setup.event.maps?.enabled) {
      this.$router.push("/");
    }
  },
};
</script>