<template>
  <div class="tabs-parent">
    <b-tabs v-model="activeMap" :animated="false" expanded>
      <b-tab-item
        v-for="(map, i) in this.maps"
        :label="map.title ?? `${ $t('event.map') } ${i + 1}`"
        :key="map.id"
        ref="maps"
      >
        <div class="panzoom-parent">
          <div class="panzoom">
            <img class="svgMap" :src="map.image.url" />
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
    <b-button v-if="this.maps.length > 0" class="reset-button" type="is-primary" @click="ResetView()">
      <d-icon icon="FaRotateLeft" class="mx-2" />
    </b-button>
  </div>
</template>

<script>
import Panzoom from "@panzoom/panzoom";

export default {
  data() {
    return {
      activeMap: null,
      panzoom: [],
      maps: []
    };
  },
  computed: {},
  watch: {
    activeMap() {
      this.ResetView();
    },
  },
  methods: {
    ResetView() {
      for (var i = 0; i < this.maps.length; i++) {
        this.panzoom[i].reset({ animate: false });
      }
    },
  },
  async mounted() {
    const data = await this.$store.dispatch("event/GetRawData", "maps");

    console.log('🍖 data', data);

    this.maps = this.$store.state.event.maps;

    this.$nextTick(() => {

      for (var i = 0; i < this.maps.length; i++) {
      const map = this.maps[i];

      this.panzoom[i] = Panzoom(
        this.$refs.maps[i].$el.querySelector(".panzoom"),
        {
        minScale: map.minZoom ?? 0.5,
        maxScale: map.maxZoom ?? 5,
        startScale: map.initialZoom ?? 1,
        }
      );
      this.panzoom[i].pan("5%", "50%");
      }

      if (this.maps.length > 0) {
        this.activeMap = 0; // Set the first tab as active
      }

      this.$store.commit("StopLoading")
    });

    
  },
};
</script>

<style lang="scss" scoped>

.b-tabs:not(:last-child) {
  margin-bottom: 0px !important;
  height: 100%;
}

.tabs-parent {
  position: relative;
  height: 100%;

  .reset-button {
    position: absolute;
    top: 3.5rem;
    right: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }
}

.panzoom-parent {
  position: relative;
  margin: 0px;
  padding: 0px;
  height: 100%;
}
.panzoom {
  position: relative;
}
.panzoom img {
  display: block;
  padding: 20px;
  margin: 0 auto;
}

/* Ejemplos de override tamaños */
.svgMap {
  scale: 1.4;
}
</style>

<style lang="scss">
.tab-content {
  padding: 0 !important;
  height: 100%;

  .tab-item {
    height: 100%;
  }
  
}
</style>
